<!-- 更多 -->
<template>
  <div id="more">
    <div class="main">
      <div>
        <!-- 头部地理位置和搜索框 -->
        <div class="head">
          <!-- 顶部NavBar 开始 -->
          <van-nav-bar
            :title="$t('moreMpplications.title')"
            :left-text="$t('main.return')"
            left-arrow
            style="background-color: rgba(255,255,255,0);"
            @click-left="onClickLeft"
          />
        </div>
      </div>
      <div class="outboxstyle">
        <div style="font-size:16px;border-radius:16px 16px 0px 0px;padding:12px">
          <van-form>
            <van-row style="margin-bottom:16px">
              <van-col :span="24">
                <div class="top-search">
                  <van-field
                    v-model="listQuery.projectName"
                    name=""
                    left-icon="searchPng"
                    label=""
                    :placeholder="$t('moreMpplications.input')"
                    class="picker"
                    clearable
                    @input="searchChange(listQuery.projectName)"
                  />
                  <img src="../../assets/icon/search.png">
                </div>
              </van-col>
            </van-row>
            <van-row>
              <div v-show="listQuery.projectName===''">
                <div v-for="(item ,itemIndex) in proTypeList" :key="itemIndex" class="app-part">
                  <div class="part-title">
                    <span>{{ item.name }}</span>
                    <img v-show="item.backIcon" src="@/assets/icon/back.png" @click="backList(item,itemIndex)">
                  </div>
                  <div class="flex">
                    <div
                      v-for="son in item.children"
                      v-show="(son.type === '1' ||son.proModelUrl === 'tuitionAndDorm'||son.proModelUrl === 'nameStandardImport' ||son.proModelUrl === 'standard' || son.proModelUrl === 'common' || son.proModelUrl === 'nameImport' || son.proModelUrl === 'mealsFee' || son.proModelUrl === 'meeting' || son.proModelUrl === 'eCardRecharge' || son.proModelUrl === 'electric' || son.isParentProject === 'YES')"
                      :key="son.name"
                      class="imgBox"
                      @click="goToPay(son,itemIndex)"
                    >
                      <img v-if="son.type === '1'" src="../../assets/icon/icon_app97.png" class="img img-size">
                      <img v-if="son.type === '2'" :src="son.imgUrl" class="img img-size">
                      <p class="textname wrapText1">{{ son.name }}</p>
                    </div>
                    <!-- <div v-show="item.emptyFlag" class="empty-part">
                      <img src="../../assets/icon/empty.png">
                      暂无缴费项目
                    </div> -->
                  </div>
                </div>
              </div>
              <div v-show="listQuery.projectName!==''" class="search-part app-part">
                <div class="part-title">搜索结果</div>
                <div class="flex">
                  <div
                    v-for="(son,itemIndex) in allProjectList"
                    v-show="son.showFlag"
                    :key="itemIndex"
                    class="imgBox"
                    @click="goToPay(son,itemIndex)"
                  >
                    <img :src="son.imgUrl" alt="" class="img img-size">
                    <p class="textname wrapText1">{{ son.projectName }}</p>
                  </div>
                </div>
              </div>
            </van-row>
          </van-form>
        </div>
      </div>
      <!-- 回到顶部按钮 -->
      <v-top />
    </div>
    <ReiTabbar />
  </div>
</template>
<script>
import { getProjectIsAllowPayment } from '@/api/project'
import { Toast } from 'vant'
import { getProjectTypeAndProjectInfoListInit, getProjectTypeAndProjectInfoList } from '@/api/projectType'
import { getAllProjectList } from '@/api/project'
// import { getStuNotice } from '@/api/notice'
import ReiTabbar from '../components/ReiTabbar.vue'
export default {
  name: 'MoreMpplications',
  components: {
    ReiTabbar
  },
  data() {
    return {
      imgTemp: [
        { imgUrl: require('../../assets/newImages/icon8.png') }
      ],
      collapseList: [
      ],
      allProjectList: [],
      proTypeList: [],
      listQuery: {
        projectName: ''
      }
    }
  },
  beforeCreate() {
    document.querySelector('body').setAttribute('style', 'background: #F5F5F5')
  },
  mounted() {
    this.init()
  },
  methods: {
    // 应用文件夹返回
    backList(item, index) {
      getProjectTypeAndProjectInfoList(item.id).then(response => {
        this.proTypeList[index].children = response.data
        this.proTypeList[index].emptyFlag = false
        this.proTypeList[index].backIcon = false
        this.proTypeList[index].name = this.proTypeList[index].name.split('>')[0]
      })
    },
    // 检索方法
    searchChange(msg) {
      for (var i = 0; i < this.allProjectList.length; i++) {
        // console.log(this.allProjectList[i].projectName)
        // console.log(typeof this.allProjectList[i].projectName.indexOf(msg))
        this.allProjectList[i].showFlag = false
        if (this.allProjectList[i].projectName.indexOf(msg) !== -1) {
          this.allProjectList[i].showFlag = true
        }
      }
    },
    // 跳转项目缴费页面
    goToPay(son, itemIndex) {
      if (son.type === '1') {
        // 是父项目
        getProjectTypeAndProjectInfoList(son.id).then(res => {
          const data = res.data
          if (data.length < 1) {
            this.$set(this.proTypeList[itemIndex], 'emptyFlag', true)
          }
          for (let i = 0; i < res.data.length; i++) {
            this.$set(data[i], 'searchShow', true)
          }
          this.$set(this.proTypeList[itemIndex], 'children', data)
          this.$set(this.proTypeList[itemIndex], 'backIcon', true)
          this.$set(this.proTypeList[itemIndex], 'name', this.proTypeList[itemIndex].name + '>' + son.name)
        })
      } else {
        // 子项目  直接跳转
        getProjectIsAllowPayment(son.id).then(response => {
          this.messageCode = response.messageCode
          if (this.messageCode !== '0') {
            Toast.fail({
              message: response.data,
              type: 'error'
            })
            return false
          } else {
            // 20210722 add by lihongyan 如果是电费electric，路由地址加上factoryCode厂商代码
            if (son.proModelUrl === 'electric') {
              this.$router.push({ name: son.proModelUrl + 'Pay' + son.factoryCode, query: { projectId: son.id }})
            } else if (son.proModelUrl === 'mealsFee') {
              this.$router.push({ name: son.proModelUrl + 'Pay' + son.factoryCode, query: { projectId: son.id }})
            } else if (son.proModelUrl === 'standard') {
              this.$router.push({ name: 'common' + son.proModelUrl + 'Pay', query: { projectId: son.id }})
            } else {
              // 根据不同Mode要进不同页面走不同接口
              this.$router.push({ path: son.proModelUrl + 'Pay', query: { projectId: son.id }})
            }
          }
        })
      }
    },
    init() {
      // 获取 首页获取项目类型列表 携带一级项目
      getProjectTypeAndProjectInfoListInit(window.localStorage.getItem('schoolcode')).then(response => {
        this.proTypeList = response.data
        for (let i = 0; i < this.proTypeList.length; i++) {
          this.proTypeList[i].emptyFlag = true
          this.proTypeList[i].backIcon = false
          if (this.proTypeList[i].children) {
            for (let j = 0; j < this.proTypeList[i].children.length; j++) {
              // 设置检索标记 searchShow
              this.$set(this.proTypeList[i].children[j], 'searchShow', true)
              // 设置显示为空flag
              var son = this.proTypeList[i].children[j]
              if ((son.type === '1' || son.proModelUrl === 'tuitionAndDorm' || son.proModelUrl === 'common' || son.proModelUrl === 'meeting' || son.isParentProject === 'YES') && son.searchShow === true) {
                this.proTypeList[i].emptyFlag = false
              }
            }
          }
        }
      })
      getAllProjectList().then(response => {
        this.allProjectList = response.data
      })
    },
    // 返回
    onClickLeft() {
      this.$router.go(-1)
    },
    // 带参数跳转页面
    dealwith(item) {
      this.$router.push({
        path: '/login'
      })
    }
  }
}
</script>
<style scoped lang="scss">
// 应用模块重置css
.app-part{
  margin-bottom: 30px;
  .flex{
    background-color: #fff;
    border-radius: 10px;
      padding:  20px 20px 40px;
    margin-top: 10px;
  }
}
.part-title{
  color: #2E2E2E;
  font-size: 14px;
  margin: 0 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  img{
    width: 15px;
  }
}
.wrapText{
  line-height: 30px;
  width:80%;
  overflow:hidden;
  text-overflow:ellipsis;
  display:-webkit-box;
  -webkit-box-orient:vertical;
  -webkit-line-clamp:1;
  color: #2E2E2E;
  height:14px;
  font-size:14px;
}
.wrapText1{
   line-height: 40px;
    font-weight: normal !important;
    width: 100%;
    overflow: visible;
    text-overflow: visible;
    //display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    color: #2E2E2E;
    height: 8px;
    font-size: 8px;
}
.imgBox{
  width: 25%;
  padding: 4px;
  margin-top: 8px ;
  img{
    margin: 7px auto;
    width: 46px;
    height: 46px;
    border: 1px solid #D0D0D0;
    border-radius: 6px;
  }
  .text{
    text-align: center;
    width: 100%;
    margin: 0 auto;
    height:12px;
    font-size:12px;
    font-weight:300;
    color:rgba(46,46,46,1);
    line-height:12px;
  }
  .textname{
    text-align: center;
    // width:60px;
    margin: 0 auto;
    height: 12px;
    font-size: 8px;
    font-weight: 550;
    color: rgba(46, 46, 46, 1);
    line-height: 12px;
  }
}
.title-class{
    color: #2F9FF2;
}
.border{
    border:1px solid #D0D0D0;
}
.collapse{
    border-bottom: 1px solid #D0D0D0;
}
#more {
  .van-cell {
  line-height: 30px;
  padding:0;
  }
  .van-icon__image {
    width: 40px;
    height: 30px;
}
  position: relative;
  background-color: #eff6ff;
  .head {
    height: 84px;
    width: 100%;
    background:linear-gradient(135deg, #3793e5 0%, #4046a6 100%);
    background-size: 100% 100%;
    background-repeat: no-repeat;
  }
}
.outboxstyle {
  // padding: 0px 10px 10px 10px;
  background-color: #F5F5F5;
  border-radius: 10px;
  margin-top: -20px;
  margin-bottom: 100px;
  width: 100%;
  height: 100%;
}
.boxstyle {
  margin: 10px 15px 0 15px;
  background-color: #ffffff;
  border: 1px white solid;
  border-radius: 10px;
  box-shadow: 0px 4px 10px #dde9f3;
  p{
    font-size:16px;
    font-weight:700;
    margin: 20px;
  }
}
.buttontext{
  width:90px;
  border-radius:5px
}
.bottom{
  width: 100%;
  background: #ffffff;
  height: 80px;
  margin-top:20px;
}
.bottombutton{
  margin: 20px;
  width:90%;
  height: 40px;
}
.van-cell__value {
  span{
    color: #333333;
  }
}
#totalpay {
  .van-cell__value {
  span{
    color: #3BA4F2;
  }
}
}
</style>
<style>
.van-nav-bar .van-icon {
    color: #ffffff;
}
.van-nav-bar__title {
    color: #ffffff;
}
.van-nav-bar__text {
    color: #ffffff;
}
[class*='van-hairline']::after {
    border: none;
}
.van-nav-bar {
  height: 80px;
}
</style>
<style lang="scss" scoped>
.van-row{
  margin-bottom: 10px;
  .van-collapse-item {
    margin-bottom: 10px;
    box-shadow: 0px 0px 10px 0px rgba(47, 159, 242, 0.04);
  }
  .van-cell:not(:last-child)::after{
    position: initial;
  }
}
::v-deep .van-cell.van-cell--clickable.van-collapse-item__title{
  border-radius: 8px;
  color: #747474;
}
::v-deep .van-cell.van-cell--clickable.van-collapse-item__title.van-collapse-item__title--expanded{
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}
::v-deep .van-collapse-item__wrapper{
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
}
.empty-part{
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  margin: 14px;
  font-size: 12px;
  color: #D0D0D0;
  img{
    height: 14px;
    margin-right: 5px;
  }
}
.main{
  margin-bottom:50px;
}
.box{
  width: 86%;
  margin:0 auto;
  padding:0 10%;
  height:120px;
  background:linear-gradient(135deg,rgba(55,147,229,1) 0%,rgba(61,105,199,1) 100%);
  border-radius:12px;
}
.img-box{
width:60px;
height:60px;
border:1px solid #ffffff;
background:#fff;
border-radius:50% ;
margin-top:26px;
}
.text-box{
margin-top:20px;

  p {
    font-size:14px;
    line-height: 30px;
    height: 20px;
    color: rgba(216,235,244,1);
    span{
    color: #FFFFFF;
    }
  }
}
.picker{
  border-radius: 4px;
  padding: 5px 10px !important;
}
.top-search{
  position: relative;
  margin-bottom: 10px;
  img{
    width: 16px;
    position: absolute;
    top: 12px;
    left: 9px;
  }
}
</style>
